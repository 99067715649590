import React from 'react'
import style from './index.module.less'
import { Highlight_Bose ,Highlight_3dof,
    Highlight_3dof_mobile,
    Highlight_FOV,
    Highlight_FOV_mobile,
    Highlight_X1,
    Highlight_X1_Mobile,
    Highlight_audio,
    Highlight_audio_mobile,
    Highlight_display,
    Highlight_display_mobile,
    Highlight_lens,
    Highlight_lens_mobile,
    Highlight_nits,
    Highlight_nits_mobile,
    Highlight_ultrawide,
    Highlight_ultrawide_mobile,
    Highlight_audio_JP,
    Highlight_audio_mobile_JP,
} from '@/images/one/index'
import { useIntl } from "react-intl"
import useBigScreen from "@/hooks/bigScreen"
import getCountry from '@/utils/getCountry.js'

const Highlights = () => {
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const region = getCountry()

    // intl.formatMessage({ id: "one_part3_text1" })
    const highlight_arr=[
        {
            img:Highlight_X1,
            title:intl.formatMessage({ id: "one_highlight1_title" }),
            desc:intl.formatMessage({ id: "one_highlight1_desc" }),
            type:'white',
            grid:ifPc?"1 / 1 / 3 / 2":'1 / 1 / 3 / 3'
        },{
            img:Highlight_display,
            title:'',
            desc:intl.formatMessage({ id: "one_highlight2_desc" }),
            type:'black',
            grid:ifPc?"1 / 2 / 2 / 3":"1 / 3 / 2 / 4"
        },{
            img:Highlight_ultrawide,
            title:intl.formatMessage({ id: "one_highlight3_title" }),
            desc:intl.formatMessage({ id: "one_highlight3_desc" }),
            type:'white',
            grid:ifPc?"1 / 3 / 2 / 5":"2 / 3 / 3 / 4"
        },{
            img:Highlight_3dof,
            title:intl.formatMessage({ id: "one_part3_content1_title" }),
            desc:intl.formatMessage({ id: "one_highlight4_desc" }),
            type:'black',
            grid:ifPc?"2 / 2 / 4 / 4":"3 / 1 / 4 / 4"
        },{
            img:Highlight_nits,
            title:intl.formatMessage({ id: "one_highlight5_title" }),
            desc:intl.formatMessage({ id: "one_highlight5_desc" }),
            type:'white',
            grid:ifPc?"2 / 4 / 4 / 5":"4 / 1 / 5 / 2"
        },{
            img:Highlight_FOV,
            title:intl.formatMessage({ id: "one_part3_content3_title" }),
            desc:intl.formatMessage({ id: "one_highlight6_desc" }),
            type:'black',
            grid:ifPc?"3 / 1 / 5 / 2":"4 / 2 / 5 / 4"
        },{
            img:Highlight_lens,
            title:'',
            desc:intl.formatMessage({ id: "one_highlight7_desc" }),
            type:'white',
            grid:ifPc?"4 / 2 / 5 / 3":"5 / 1 / 6 / 3"
        },{
            img:region=='jp'||region=='kr'?Highlight_audio_JP:Highlight_audio,
            title:'',
            desc:intl.formatMessage({ id: "one_highlight8_desc" }),
            type:'black',
            grid:ifPc?"4 / 3 / 5 / 5":"5 / 3 / 6 / 4"
        },
        
    ]
    const highlight_arr_mobile=[
        {
            img:Highlight_X1_Mobile,
            title:intl.formatMessage({ id: "one_highlight1_title" }),
            desc:intl.formatMessage({ id: "one_highlight1_desc" }),
            type:'white',
            grid:'1 / 1 / 3 / 3'
        },{
            img:Highlight_FOV_mobile,
            title:intl.formatMessage({ id: "one_part3_content3_title" }),
            desc:intl.formatMessage({ id: "one_highlight6_desc" }),
            type:'black',
            grid:"1 / 3 / 2 / 4"
        },{
            img:Highlight_nits_mobile,
            title:intl.formatMessage({ id: "one_highlight5_title" }),
            desc:intl.formatMessage({ id: "one_highlight5_desc" }),
            type:'white',
            grid:"2 / 3 / 3 / 4"
        },{
            img:Highlight_3dof_mobile,
            title:intl.formatMessage({ id: "one_part3_content1_title" }),
            desc:intl.formatMessage({ id: "one_highlight4_desc" }),
           
            type:'black',
            grid:"3 / 1 / 4 / 4"
        },{
            img:Highlight_display_mobile,
            title:'',
            desc:intl.formatMessage({ id: "one_highlight2_desc" }),
           
            type:'black',
            grid:"4 / 1 / 5 / 2"
        },{
            img:Highlight_lens_mobile,
            title:'',
            desc:intl.formatMessage({ id: "one_highlight7_desc" }),
            type:'white',
            grid:"4 / 2 / 5 / 4 "
        },{
            img:Highlight_ultrawide_mobile,
            title:intl.formatMessage({ id: "one_highlight3_title" }),
            desc:intl.formatMessage({ id: "one_highlight3_desc" }),
            type:'white',
            grid:" 5 / 1 / 6 / 3"
        },{
            img:region=='jp'||region=='kr'?Highlight_audio_mobile_JP:Highlight_audio_mobile,
            title:'',
            desc:intl.formatMessage({ id: "one_highlight8_desc" }),
            type:'black',
            grid:"5 / 3 / 6 / 4"
        },
        
    ]
    const show_hight_arr=ifPc?highlight_arr:highlight_arr_mobile
    
    return (
        <div className={style.box}>
            <div className={style.title}>
                {intl.formatMessage({ id: "one_part3_text1" })}
            </div>
            <div className={style.highlight}>
                {show_hight_arr.map((item,index)=>(
                    <div className={style.highlightItem} key={`highlight${index}`} style={{gridArea:item.grid,backgroundImage:`url(${item.img})`}} >
                        {!!item.title&& <div className={style.highlightItemTitle} style={{color:item.type}}>
                            {item.title}
                        </div>}
                        {!!item.desc&& <div className={style.highlightItemDesc} style={{color:item.type=='black'?'rgba(0,0,0,0.4)':"rgba(255,255,255,0.4)"}}>
                            {item.desc}
                        </div>}
                    </div>
                ))}
            </div>
       
        </div>
    )
}

export default Highlights