import React from "react";
import style from './index.module.less'
import {
    ScenePic_Gaming,
    ScenePic_Projector,
    ScenePic_productivity,
    ScenePic_0,
    ScenePic_1,
    ScenePic_2,
    ScenePic_3,
    ScenePic_4,
    ScenePic_5,
    ScenePic_6,
    ScenePic_7,
} from '@/images/one/index'
import { SwiperSlide } from "swiper/react";
import { SwiperTrack } from '@/components/index'
import { useIntl } from "react-intl"

const SwiperMobile = () => {
    const intl = useIntl()
   
    const list1 = [ScenePic_Projector, ScenePic_0, ScenePic_1, ScenePic_2]
    const list2 = [ScenePic_productivity, ScenePic_3, ScenePic_4, ScenePic_5]
    const list3 = [ScenePic_Gaming, ScenePic_0, ScenePic_6, ScenePic_7]
    return (
        <div className={style.box}>
            <SwiperTrack reverseDirection={true} >
                {list1.map((item, index) => (
                    <SwiperSlide style={{ margin: '0' }}>
                        <div className={style.swiperItem} key={`oneSwiperImg${index}`} style={{ backgroundImage: `url(${item})` }}>
                            {index==0&&<div className={style.title}  dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "one_swiper_text1" })}` }} />
                         }
                        </div>
                    </SwiperSlide>
                ))}
            </SwiperTrack>
            <SwiperTrack reverseDirection={false}>
                {list2.map((item, index) => (
                    <SwiperSlide style={{ margin: '0' }}>
                        <div className={style.swiperItem} key={`oneSwiperImg${index}`} style={{ backgroundImage: `url(${item})` }}>   {index==0&&<div className={style.title}  dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "one_swiper_text2" })}` }} />}</div>
                    </SwiperSlide>
                ))}
            </SwiperTrack>


            <SwiperTrack reverseDirection={true}>
                {list3.map((item, index) => (
                    <SwiperSlide style={{ margin: '0' }}>
                        <div className={style.swiperItem} key={`oneSwiperImg${index}`} style={{ backgroundImage: `url(${item})` }}>
                        {index==0&&<div className={style.title} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "one_swiper_text3" })}` }} /> }
                        </div>
                    </SwiperSlide>
                ))}
            </SwiperTrack>
        </div>
    )
}
export default SwiperMobile