import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import One_X1 from '@/images/SVG/One_X1.svg'
import { useIntl } from "react-intl"
import { X1_3ms } from '@/images/one/index'
import useWinSize from '@/hooks/useWinSize'
import getObjectFitSize from "@/utils/getObjFitSize"
import useBigScreen from "@/hooks/bigScreen"

const isBrowser = typeof window !== "undefined" && window
let frameCount = 8;
const images = [];
for (let i = 1; i < frameCount + 1; i++) {
    images[i - 1] = `${process.env.resourceUrl}images/one/x1-frame/X1_0000${i}.webp`;
}
const firstImg = `${process.env.resourceUrl}images/one/x1-frame/X1_00001.webp`
function preloadImages(urls) {
    const promises = urls.map((url) => {
        return new Promise((resolve, reject) => {
            const image = isBrowser && new Image()
            image.src = url;
            image.onload = () => resolve(image);
            image.onerror = () => reject(`Image failed to load: ${url}`);
        });
    });
    return Promise.all(promises);
}
const X1 = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()
    const x1BoxRef = useRef()
    const levelRef = useRef()
    const x1Ref = useRef()
    const x1SVGRef = useRef()
    const lastRef = useRef()
    const lastBoxRef = useRef()
    const lastBoxTextRef = useRef()
    const [curIdx, setCurIdx] = useState(0)
    const [imagesAry, setImagesAry] = useState([])
    const size = useWinSize()
    const canvasRef = useRef()
    const ifPc = useBigScreen(768)

    useEffect(() => {
        let imgAry = []
        const fetchData = async () => {
            imgAry = await preloadImages(images);
            setImagesAry(imgAry);
        }
        fetchData()

    }, [])
    useEffect(() => {
        let canvas = canvasRef.current
        if (!!canvas) {
            canvas.width = size.width
            canvas.height =ifPc? size.height:size.height/2
        }
        if (imagesAry.length > 0) {
            updateImage(curIdx, imagesAry)
        }
    }, [size, imagesAry, curIdx])

    useEffect(() => {
        if (!!x1BoxRef.current) {
            //文字消失
            gsap.to(levelRef.current, {
                marginBottom: '-200px',
                opacity: 0,
                duration: 0.5,
                scrollTrigger: {
                    start: "3% top",
                    end: "3% top",
                    scrub: false,
                    trigger: x1BoxRef.current,
                    toggleActions: 'play none none reverse',
                }
            })

            //执行序列帧

            //x1盒子出现 x1svg由小变大
            let x1Tween = gsap.timeline()

            let frame_count = {
                frame: 0
            };
            gsap.to(frame_count, {
                frame: 7,
                onUpdate: () => { setCurIdx(Math.floor(frame_count.frame)) },
                scrollTrigger: {
                    start: "3% top",
                    end: "15% top",
                    trigger: x1BoxRef.current,
                    scrub: true,
                    toggleActions: 'play none none reverse',
                },
            })

            x1Tween.to(x1Ref.current, {
                opacity: 1,
                scrollTrigger: {
                    start: "15% top",
                    end: "28% top",
                    scrub: true,
                    trigger: x1BoxRef.current,
                    toggleActions: 'play none none reverse',
                }
            })
                .to(x1SVGRef.current, {
                    scale: 1,
                    scrollTrigger: {
                        start: "15% top",
                        end: "28% top",
                        scrub: true,
                        trigger: x1BoxRef.current,
                        toggleActions: 'play none none reverse',
                    }
                }, '>')
                .to(x1SVGRef.current.querySelector("svg path"), {
                    fill: '#FFAA25',
                    scrollTrigger: {
                        start: "15% top",
                        end: "28% top",
                        scrub: true,
                        trigger: x1BoxRef.current,
                        toggleActions: 'play none none reverse',
                    }
                }, '>')
                //盒子左平移100vw
                .to(x1Ref.current, {
                    x: '-100vw',
                    scrollTrigger: {
                        start: "28% top",
                        end: "42% top",
                        scrub: true,
                        trigger: x1BoxRef.current,
                        toggleActions: 'play none none reverse',
                    }
                })
                //最后一层出现
                .to(lastRef.current, {
                    opacity: 1,
                    scrollTrigger: {
                        start: "42% top",
                        end: "57% top",
                        scrub: true,
                        trigger: x1BoxRef.current,
                        toggleActions: 'play none none reverse',
                    }
                })
                .to(lastBoxRef.current, {
                    opacity: 1,
                    scrollTrigger: {
                        start: "57% top",
                        end: "bottom bottom",
                        scrub: true,
                        trigger: x1BoxRef.current,
                        toggleActions: 'play none none reverse',
                    }
                }).to(lastBoxTextRef.current, {
                    y: '-50%',
                    scrollTrigger: {
                        start: "57% top",
                        end: "bottom bottom",
                        scrub: true,
                        trigger: x1BoxRef.current,
                        toggleActions: 'play none none reverse',
                    }
                }, '>')
        }

    }, [])
    const updateImage = (index, imglist) => {
        let canvas = canvasRef.current
        let ctx = canvas.getContext("2d");
        const { sx, sy, swidth, sheight, x, y, width, height } = getObjectFitSize('cover', size.width,ifPc? size.height:size.height/2, 2560, 1440)
        ctx.drawImage(imglist[index], sx, sy, swidth, sheight, 0, 0, width, height);
    }
    return (
        <div className={style.box} ref={x1BoxRef}>
            <div className={style.sticky}>
                <div className={style.level} style={{ backgroundImage: `url(${ifPc&&firstImg})` }}>
                    <canvas ref={canvasRef}
                        width={size.width}
                        height={ifPc? size.height:size.height/2} />
                    <div className={style.levelBox} ref={levelRef}>
                        <div className={style.levelTitle}>
                            {intl.formatMessage({ id: "one_part6_text1" })}
                        </div>
                        <div className={style.levelDesc}>
                            {intl.formatMessage({ id: "one_part6_text2" })}
                        </div>
                        <div className={style.levelTip}>
                            {intl.formatMessage({ id: "one_part6_text3" })}
                        </div>
                    </div>

                </div>
                <div className={style.x1} ref={x1Ref}>
                    <div className={style.x1SVG} ref={x1SVGRef}>
                        <One_X1 />
                    </div>
                    <div className={style.latency}>
                        <div className={style.latencyTitle}>3 ms</div>
                        <div className={style.latencyDesc}>
                            {intl.formatMessage({ id: "one_part6_text4" })}
                        </div>
                    </div>
                </div>
                <div className={style.last} ref={lastRef} style={{ backgroundImage: `url(${X1_3ms})` }}>
                    <div className={style.lastBox} ref={lastBoxRef}>
                        <div className={style.lastBoxText} ref={lastBoxTextRef} ><span dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "one_part6_text5" })}` }}></span>     </div>

                    </div>

                </div>
            </div>



        </div>
    )
}

export default X1