import React, { useState, useRef } from 'react'
import style from './index.module.less'
import Yellow_add_icon from '@/images/SVG/Yellow_add_icon.svg'
import Yellow_close_icon from '@/images/SVG/Yellow_close_icon.svg'
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import {
    Mode_Follow,
    Mode_Sideview,
    Mode_Anchor,
    Mode_UltraWide,
    
} from '@/images/onepro/index'
import{
    GF_Anchor_1,
    GF_Anchor_2,
    GF_Follow_1,
    GF_SideView_1,
    GF_Ultrawide_1,
}from '@/images/one/index'
import { gsap } from 'gsap'
import classNames from 'classnames'


const Anchor = () => {
    const intl = useIntl()
    const content_arr = [
        {
            img: Mode_Anchor,
            name: intl.formatMessage({ id: "one_part5_content1_title" }),
            desc: intl.formatMessage({ id: "one_part5_content1_desc" }),
            type:'Native 3 DoF',
            btn: [
                {
                    text: intl.formatMessage({ id: "one_part5_content1_btn1" }),
                    content: {
                        img: GF_Anchor_1,
                        title: intl.formatMessage({ id: "one_part5_content1_btn1_text" })
                    }
                }, {
                    text: intl.formatMessage({ id: "one_part5_content1_btn2" }),
                    content: {
                        img: GF_Anchor_2,
                        title: intl.formatMessage({ id: "one_part5_content1_btn2_text" }),
                        desc: ""
                    }
                }
            ]

        }, {
            img: Mode_Follow,
            name: intl.formatMessage({ id: "one_part5_content2_title" }),
            desc: intl.formatMessage({ id: "one_part5_content2_desc" }),
            type:'0 DoF',

            btn: [
                {
                    text: intl.formatMessage({ id: "one_part5_content2_btn1" }),
                    content: {
                        img: GF_Follow_1,
                        title: intl.formatMessage({ id: "one_part5_content2_btn1_text" }),
                        desc: ""
                    }
                }
            ]

        }, {
            img: Mode_UltraWide,
            name: intl.formatMessage({ id: "one_part5_content3_title" }),
            desc: intl.formatMessage({ id: "one_part5_content3_desc" }),
            type:'Native 3 DoF',

            btn: [
                {
                    text: intl.formatMessage({ id: "one_part5_content3_btn1" }),
                    content: {
                        img: GF_Ultrawide_1,
                        title: intl.formatMessage({ id: "one_part5_content3_btn1_text" }),
                        desc: ""
                    }
                }
            ]

        }, {
            img: Mode_Sideview,
            name: intl.formatMessage({ id: "one_part5_content4_title" }),
            desc: intl.formatMessage({ id: "one_part5_content4_desc" }),
            type:'0 DoF',

            btn: [
                {
                    text: intl.formatMessage({ id: "one_part5_content4_btn1" }),
                    content: {
                        img: GF_SideView_1,
                        title: intl.formatMessage({ id: "one_part5_content4_btn1_text" }),
                        desc: ""
                    }
                }
            ]

        },
    ]
    const [showId, setShowId] = useState([0, 0])
    const [ifShow, setIfShow] = useState(false)
    const ifPc = useBigScreen(768)

    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
        containerRef.current.style.cursor = "grabbing";
    };

    const handleMouseLeaveOrUp = () => {
        setIsDragging(false);
        containerRef.current.style.cursor = "grab";
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = x - startX;

        // 使用 GSAP 动画滚动
        gsap.to(containerRef.current, {
            scrollLeft: scrollLeft - walk,
            duration: 0.3,
            ease: "power2.out",
        });
    };
    return (
        <div className={style.box}>
            <div className={classNames(style.content, 'scroll-container')}
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseLeaveOrUp}
                onMouseUp={handleMouseLeaveOrUp}
                onMouseMove={handleMouseMove}
                >
                {content_arr.map((item, index) => (
                    <div className={style.contentItem} key={`anchor_contentItem${index}`}>
                        <div className={style.contentItemImg}>
                            <div className={style.contentItemImgType}>{item.type}</div>
                            <video autoPlay playsInline loop muted width="100%" >
                                <source src={item.img} type="video/mp4" />
                            </video>
                        </div>
                        <div className={style.contentItemBox} >
                            <div className={style.contentItemTitle}>{item.name}</div>
                            <div className={style.contentItemDesc} dangerouslySetInnerHTML={{ __html: item.desc }}>
                                
                            </div>
                            <div className={style.contentItemBtn}>
                                {item.btn.map((btnItem, btnKey) => (
                                    <div className={style.contentItemBtnChild}
                                        key={`anchor_contentItemBtn${index}_${btnKey}`}
                                        onClick={() => {
                                            setShowId([index, btnKey]);
                                            setIfShow(true)
                                        }}
                                    >
                                        <span>{btnItem.text}</span>
                                        <Yellow_add_icon />
                                    </div>
                                ))}
                            </div>
                        </div>


                    </div>
                ))}
            </div>
            {ifPc ? <div className={style.show} style={{ opacity: ifShow ? 1 : 0, zIndex: ifShow ? 2 : '-1' }}>
                <div className={style.showBox} style={{backgroundImage:`url(${ content_arr[showId[0]].btn[showId[1]].content.img})`}}>
                    <div className={style.showBoxClose} onClick={() => setIfShow(false)}>
                        <Yellow_close_icon />
                    </div>
                    <div className={style.showBoxTitle}>
                        {
                            content_arr[showId[0]].btn[showId[1]].text
                        }
                    </div>
                    <div className={style.showBoxDesc}>
                        {
                            content_arr[showId[0]].btn[showId[1]].content.title
                        }
                    </div>
                </div>
            </div> : <div className={style.show} style={{ opacity: ifShow ? 1 : 0, zIndex: ifShow ? 2 : '-1' }}>
                <div className={style.showBoxClose} onClick={() => setIfShow(false)}>
                    <Yellow_close_icon />
                </div>
                <div className={style.showBox} style={{backgroundImage:`url(${ content_arr[showId[0]].btn[showId[1]].content.img})`}}>


                </div>
                <div className={style.showBoxTitle}>
                    {
                        content_arr[showId[0]].btn[showId[1]].text
                    }
                </div>
                <div className={style.showBoxDesc}>
                    {
                        content_arr[showId[0]].btn[showId[1]].content.title
                    }
                </div>
            </div>}


        </div>
    )
}

export default Anchor