import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.less'
import { useIntl } from "react-intl"
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from 'classnames'
import {
    ScenePic_Gaming,
    ScenePic_Projector,
    ScenePic_productivity,
    ScenePic_0,
    ScenePic_1,
    ScenePic_2,
    ScenePic_3,
    ScenePic_4,
    ScenePic_5,
    ScenePic_6,
    ScenePic_7,
} from '@/images/one/index'

const SwiperScroll = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()
    const swiperBoxRef = useRef()
    const bottomBoxRef = useRef()
    const topBoxRef = useRef()
    const contentRef = useRef()


    const top_arr = [
        {
            title: intl.formatMessage({ id: "one_swiper_text1" }),
            img: ScenePic_Projector
        },
        {
            title: intl.formatMessage({ id: "one_swiper_text2" }),
            img: ScenePic_productivity
        },
        {
            title: intl.formatMessage({ id: "one_swiper_text3" }),
            img: ScenePic_Gaming
        },
        {
            img: ScenePic_0
        },
        {
            img: ScenePic_1
        }
    ]

    const bottom_arr = [
        {
            img: ScenePic_2
        },
        {
            img: ScenePic_3
        }, {
            img: ScenePic_4
        }
    ]

    useEffect(() => {
        if (!!swiperBoxRef.current) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: swiperBoxRef.current,
                    start: "top top",
                    end: "bottom top",
                    scrub: true,
                    pin: true,
                    // markers: true,
                }
            })
            tl.from(topBoxRef.current, {
                scale: 1.5,
            })
            tl.to('.one_swiper_top0', {
                opacity: 0,
            }, '<')
            tl.to(topBoxRef.current, {
                x: '-10vw',
            }, '<')
            tl.to('.one_swiper_top0', {
                opacity: 0
            })
            tl.to('.one_swiper_top_img1', {
                opacity: 1,
                scale: 1
            })
            tl.to(topBoxRef.current, {
                x: '-20vw',
            }, '<')
            tl.to('.one_swiper_top_img2', {
                opacity: 1,
                scale: 1
            })
            tl.to(topBoxRef.current, {
                x: '-30vw',
            }, '<')

            tl.from(bottomBoxRef.current, {
                height: 0,
                opacity: 0,
            })
            tl.to(contentRef.current, {
                scale: 1
            }, '<')
            tl.to(topBoxRef.current, {
                x: '-50vw'
            })
            tl.from(bottomBoxRef.current, {
                x: '-20vw'
            }, "<")
        }

    }, [])

    return (
        <div className={style.outBox}>
            <div className={style.box} ref={swiperBoxRef}>
                <div className={style.content}  ref={contentRef}>
                    <div className={style.top}>
                        <div className={style.topBox} ref={topBoxRef}>
                            {top_arr.map((item, index) => (
                                <div className={classNames(style.topBoxItem)} key={`one_swiper_top${index}`}
                                style={{backgroundImage:`url(${item.img})`}}
                                >

                                    {!!item.title && <div className={classNames(style.topBoxItemText, `one_swiper_top${index}`)} dangerouslySetInnerHTML={{ __html: `${item.title} ` }}>

                                    </div>}
                                    {index !== 0 && <div className={classNames(style.topBoxItemImg, `one_swiper_top_img${index}`)}
                                     style={{backgroundImage:`url(${item.img})`}}
                                    ></div>}

                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={style.bottom}>
                        <div className={classNames(style.bottomBox)} ref={bottomBoxRef}>
                            {bottom_arr.map((item, index) => (
                                <div className={style.bottomBoxItem} key={`one_swiper_bottom${index}`}
                                style={{backgroundImage:`url(${item.img})`}}
                                >

                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SwiperScroll