import React from 'react'
import style from './index.module.less'
import {
    morethan_pc,
    morethan_mobile
} from '@/images/one/index'
import useBigScreen from "@/hooks/bigScreen"
import { useIntl } from "react-intl"
import TipMark from '@/images/SVG/tip_mark.svg'
import White_Window from '@/images/icon/white_window.svg'
import getCountry from "@/utils/getCountry.js"

const MoreThan = () => {
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const region = getCountry()
    // else:{
    //     qusetion:"What does 3 DoF and 6 DoF mean for AR Glasses?",
    //     link:""
    // }
    return (
        <div className={style.box} style={{ backgroundImage: `url(${ifPc ? morethan_pc : morethan_mobile})` }}>
            <div className={style.title} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: `one_part1_text1` })}` }}>

            </div>
            <div className={style.desc}>
                {intl.formatMessage({ id: "one_part1_text2" })}
                {region !== 'jp' && region !== 'kr' && <div className={style.tip}>
                    <TipMark />

                    <div className={style.tipWindow} style={{ backgroundImage: `url(${White_Window})` }}>
                        <div className={style.tipWindowTitle}>
                            {intl.formatMessage({ id: "one_part1_text3" })}
                        </div>
                        <div className={style.tipWindowLink} onClick={() => window.open('https://us.shop.xreal.com/blogs/behind-the-innovation/what-is-a-3dof-vs-6dof-in-ar-glasses')}>
                            {intl.formatMessage({ id: "learn_more" })}
                        </div>
                    </div>
                </div>}


            </div>

        </div>
    )
}

export default MoreThan