import React , {useEffect,useRef} from 'react'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
    X1_icon1,
    X1_icon2,
    X1_icon3,
    chip_1,
    chip_2,
    chip_3,
    OSD_JP
}from '@/images/one/index'
import { useIntl } from "react-intl"
import getCountry from '@/utils/getCountry.js'

const CompareMobile = () =>{
    gsap.registerPlugin(ScrollTrigger)
    const region = getCountry()
    const intl = useIntl()
    const content_arr= [
        {
            img:region=='jp'?OSD_JP:chip_1,
            icon:X1_icon3,
            title:intl.formatMessage({ id: "one_part7_content3_title" }),
            desc:intl.formatMessage({ id: "one_part7_content3_desc" })
        },
        {
            img:chip_2,
            icon:X1_icon1,
            title:intl.formatMessage({ id: "one_part7_content1_title" }),
            desc:intl.formatMessage({ id: "one_part7_content1_desc" })
        },
        {
            img:chip_3,
            icon:X1_icon2,
            title:intl.formatMessage({ id: "one_part7_content2_title" }),
            desc:intl.formatMessage({ id: "one_part7_content2_desc" })
        },
      
    ]



    return(
        <div className={style.box} >
            <div className={style.content}>
                {content_arr.map((item,index)=>(
                    <div className={style.contentItem} key={`one_compareMobile${index}`}>
                        <div className={style.contentItemImg} style={{backgroundImage:`url(${item.img})`}}></div>
                        <div className={style.contentItemText}>
                            <div className={style.contentItemTextIcon}>
                                <img src={item.icon} />
                            </div>
                            <div className={style.contentItemTextBox}>
                                <div className={style.contentItemTextBoxTitle}>
                                    {item.title}
                                </div>
                                <div className={style.contentItemTextBoxDesc}>
                                    {item.desc}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CompareMobile