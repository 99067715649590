import React from 'react'
import style from './index.module.less'
import {
    ID_1_Desktop,
    ID_2_Desktop,
    ID_3_Desktop,
    ID_4_Desktop,
    ID_5_Desktop,
    ID_6_Desktop
} from '@/images/one/index'
const DetailImg = () =>{
    
    return(
        <div className={style.box}>
            <div className={style.content}>
                <div className={style.glass1} style={{gridArea:'1 / 1 / 2 / 2',backgroundImage:`url(${ID_1_Desktop})`}}></div>
                <div className={style.glass2} style={{gridArea:'1 / 2 / 3 / 4',backgroundImage:`url(${ID_3_Desktop})`}}></div>
                <div className={style.glass3} style={{gridArea:'1 / 4 / 3 / 5',backgroundImage:`url(${ID_4_Desktop})`}}></div>
                <div className={style.glass4} style={{gridArea:'2 / 1 / 4 / 2',backgroundImage:`url(${ID_2_Desktop})`}}></div>
                <div className={style.glass5} style={{gridArea:' 3 / 2 / 4 / 3',backgroundImage:`url(${ID_5_Desktop})`}}></div>
                <div className={style.glass6} style={{gridArea:'3 / 3 / 4 / 5',backgroundImage:`url(${ID_6_Desktop})`}}></div>
            </div>
        </div>
    )
}

export default DetailImg