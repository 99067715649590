import React, { useEffect, useRef } from 'react'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
    X1_icon1,
    X1_icon2,
    X1_icon3,
    chip_1,
    chip_2,
    chip_3,
    OSD_JP
} from '@/images/one/index'
import { useIntl } from "react-intl"
import getCountry from '@/utils/getCountry.js'

const Compare = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()
    const boxRef = useRef()
    const right1Ref = useRef()
    const right2Ref = useRef()
    const right3Ref = useRef()
    const x1icon1Ref = useRef()
    const x1icon2Ref = useRef()
    const x1icon3Ref = useRef()
    const contentText1Ref = useRef()
    const contentText2Ref = useRef()
    const contentText3Ref = useRef()
    const region = getCountry()

    useEffect(() => {
        if (!!boxRef.current) {
            // gsap.to(right1Ref.current,{
            //     opacity:0,
            //     zIndex:1,
            //     duration: 0.2,
            //     scrollTrigger: {
            //         start: "25% top",
            //         end: "25% top",
            //         scrub: false,
            //         trigger: boxRef.current,
            //         toggleActions: 'play none none reverse',
            //     }
            // })
            gsap.to(x1icon1Ref.current, {
                opacity: 0,
                duration: 0.2,
                scrollTrigger: {
                    start: "25% top",
                    end: "25% top",
                    scrub: false,
                    trigger: boxRef.current,
                    toggleActions: 'play none none reverse'
                }
            })
            gsap.to(contentText1Ref.current, {
                opacity: 0,
                duration: 0.2,
                scrollTrigger: {
                    start: "25% top",
                    end: "25% top",
                    scrub: false,
                    trigger: boxRef.current,
                    toggleActions: 'play none none reverse'
                }
            })
            gsap.to(x1icon2Ref.current, {
                opacity: 1,
                duration: 0.2,
                scrollTrigger: {
                    start: "25% top",
                    end: "40% top",
                    scrub: false,
                    trigger: boxRef.current,
                    toggleActions: 'play reverse play reverse'
                }
            })
            gsap.to(contentText2Ref.current, {
                opacity: 1,
                y: '-100%',
                duration: 0.2,
                scrollTrigger: {
                    start: "25% top",
                    end: "40% top",
                    scrub: false,
                    trigger: boxRef.current,
                    toggleActions: 'play reverse play reverse'
                }
            })
            gsap.to(contentText3Ref.current, {
                opacity: 1,
                y: '-100%',
                duration: 0.2,
                scrollTrigger: {
                    start: "40% top",
                    end: "bottom bottom",
                    scrub: false,
                    trigger: boxRef.current,
                    toggleActions: 'play none none reverse'
                }
            })
            gsap.to(x1icon3Ref.current, {
                opacity: 1,
                duration: 0.2,
                scrollTrigger: {
                    start: "40% top",
                    end: "bottom bottom",
                    scrub: false,
                    trigger: boxRef.current,
                    toggleActions: 'play none none reverse'
                }
            })
            gsap.to(right2Ref.current, {
                opacity: 1,
                zIndex: 2,
                duration: 0.2,
                scrollTrigger: {
                    start: "25% top",
                    end: "25% top",
                    scrub: false,
                    trigger: boxRef.current,
                    toggleActions: 'play none none reverse'
                }
            })
            gsap.to(right3Ref.current, {
                opacity: 1,
                zIndex: 3,
                duration: 0.2,
                scrollTrigger: {
                    start: "40% top",
                    end: "40% top",
                    scrub: false,
                    trigger: boxRef.current,
                    toggleActions: 'play none none reverse'

                }
            })




        }

    }, [])

    return (
        <div className={style.box} ref={boxRef}>
            <div className={style.stickyBox}>
                <div className={style.content}>
                    <div className={style.contentText}>
                        <div className={style.contentTextLogo}>
                            <img src={X1_icon3} ref={x1icon1Ref} className={style.contentTextLogo1} />
                            <img src={X1_icon2} ref={x1icon2Ref} className={style.contentTextLogo2} />
                            <img src={X1_icon1} ref={x1icon3Ref}
                                className={style.contentTextLogo3} />
                        </div>
                        <div className={style.contentTextBox}>
                            <div className={style.contentTextBox1} ref={contentText1Ref}
                            >
                                <div className={style.contentTextBoxTitle}>{intl.formatMessage({ id: "one_part7_content3_title" })}</div>
                                <div className={style.contentTextBoxDesc}>{intl.formatMessage({ id: "one_part7_content3_desc" })}</div>

                            </div>
                            <div className={style.contentTextBox2} ref={contentText2Ref}
                            >
                                <div className={style.contentTextBoxTitle}>{intl.formatMessage({ id: "one_part7_content1_title" })}</div>
                                <div className={style.contentTextBoxDesc}>{intl.formatMessage({ id: "one_part7_content1_desc" })}</div>
                            </div>
                            <div className={style.contentTextBox3} ref={contentText3Ref}
                            >
                                <div className={style.contentTextBoxTitle}>{intl.formatMessage({ id: "one_part7_content2_title" })}</div>
                                <div className={style.contentTextBoxDesc}>{intl.formatMessage({ id: "one_part7_content2_desc" })}</div>
                            </div>
                        </div>

                    </div>
                    <div className={style.contentRight}>
                        <div className={style.contentRight1} ref={right1Ref} style={{backgroundImage:`url(${region=='jp'?OSD_JP:chip_1})`}}>
                            
                        </div>
                        <div className={style.contentRight2} ref={right2Ref} style={{backgroundImage:`url(${chip_2})`}}>
                            
                        </div>
                        <div className={style.contentRight3} ref={right3Ref} style={{backgroundImage:`url(${chip_3})`}}>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Compare