import React from "react";
import style from './index.module.less'
import { useIntl } from "react-intl"
import { one_sound_logo, Bose,   sound_bose_bg1,
    sound_bose_bg2,
    sound_bose_bg3,
    Bose_JP
} from '@/images/one/index'
import getCountry from '@/utils/getCountry.js'

const SoundBose = () => {
    const intl = useIntl()
    const region = getCountry()
    const intro_arr = [
        {
            img: '',
            video: sound_bose_bg1,
            title: intl.formatMessage({ id: "one_sound_intro1_title" }),
            desc: intl.formatMessage({ id: "one_sound_intro1_desc" }),
        }, {
            img: sound_bose_bg2,
            title: intl.formatMessage({ id: "one_sound_intro2_title" }),
            desc: intl.formatMessage({ id: "one_sound_intro2_desc" }),
        }, {
            img: sound_bose_bg3,
            title: intl.formatMessage({ id: "one_sound_intro3_title" }),
            desc: intl.formatMessage({ id: "one_sound_intro3_desc" }),
        }
    ]
    return (
        <div className={style.box}>
            <div className={style.black} style={{ backgroundImage: `url(${region=='jp'||region=='kr'?Bose_JP:Bose})` }}>
                <div className={style.blackTitle}>
                    {intl.formatMessage({ id: "one_sound_title" })}
                </div>
                <div className={style.blackDesc}>
                    {intl.formatMessage({ id: "one_sound_desc" })}
                </div>
                <img src={one_sound_logo} className={style.blackImg} />
            </div>
            <div className={style.intro}>
                {intro_arr.map((item, index) => (
                    <div className={style.introItem} key={`one_sound_intro_${index}`}>
                        <div className={style.introItemBg}>
                            {!!item.video ?
                                <video autoPlay playsInline loop muted width="100%" >
                                    <source src={item.video} type="video/mp4" />
                                </video> :
                                <div className={style.introItemImg} style={{ backgroundImage: `url(${item.img})` }}>
                                </div>}
                        </div>


                        <div className={style.introItemTitle}>
                            {item.title}
                        </div>
                        <div className={style.introItemDesc} dangerouslySetInnerHTML={{ __html: item.desc }}>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

}

export default SoundBose